import React, { useEffect, useState } from "react";
import {
  TextInput,
  FormTab,
  BooleanInput,
  required,
  number,
  regex,
  useTranslate,
  Confirm,
  useNotify,
} from "react-admin";
import { Grid, Box } from "@mui/material";
import SpeedIcon from '@material-ui/icons/Speed';
import CircularProgress from "@material-ui/core/CircularProgress";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import Autorenew from "@material-ui/icons/Autorenew";
import Tooltip from "@mui/material/Tooltip";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useForm, Field } from "react-final-form";
import { BotWhitelistIPs } from "./BotUsage/BotWhitelistIPs";
import { useStyles } from "./BotProfile";
import { truncate, randomString } from "../../../helper/utils";
import { env } from "../../../env";
import botProvider from "../../../synapse/botProvider";
import { createSignature } from "../../../helper/bot";
import SuccessIcon from "@material-ui/icons/Check";
import FailedIcon from "@material-ui/icons/Close";
import { Button } from '@mui/material';

const SubTitle = ({ children }) => {
  return (<Box sx={{ whiteSpace: "nowrap", fontWeight: 'bold',marginBottom: '10px', fontSize: '0.8rem' }}>
    {children}
  </Box>)
}
const ContentWrapper = ({ children }) => {
  return  <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
    {children}
  </Box>;
}

const BotUsage = (props) => {
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const form = useForm();
  const [callbackUrl, setCallbackUrl] = useState(props.bot.configs.form_callback);
  const [testButtonIcon, setTestButtonIcon] = useState("test");
  const [copyText, setCopyText] = useState("Copied");
  const [copyCallbackURL, setCopyCallbackURL] = useState(null);
  const [isCopiedToken, setIsCopiedToken] = useState(false);
  const [generatingSecretKey, setGeneratingSecretKey] = useState(false);
  const [confirm, setConfirm] = React.useState({
    isOpen: false,
    title: "",
    content: "",
    type: "",
  });
  const [formSecret, setFormSecret] = useState("");

  useEffect(() => {
    if (props.bot.configs.form_secret) {
      setFormSecret(props.bot.configs.form_secret);
    }
  }, [props.bot]);

  const testCallbackUrl = (callbackUrl) => {
    if (testButtonIcon !== 'test') return;
    const mockData = {
      request_id: 'dummy request_id',
      room_id: 'dummy room_id',
      key_query: 'dummy key_query',
      created_at: new Date().getTime(),
      response_at: new Date().getTime(),
    }

    const signatureRequest = createSignature(mockData, props.bot.configs.form_secret);

    botProvider.testCallback(callbackUrl, mockData, signatureRequest)
      .then((res) => {
        let notifyMsg = `Callback ${res.status}`;
        if (res.status === 200 || res.status === 201) {
          notifyMsg = 'Callback test successfully';
        }
        notify(notifyMsg, { type: "success" });
        setTestButtonIcon("success");
      }).catch((err) => {
        notify(`Callback test failed: ${err.message}`, { type: "error" });
        setTestButtonIcon("failed");
      }).finally(() => {
        setTimeout(() => {
          setTestButtonIcon("test");
        }, 5000);
    });
  };

  function onGenerate(event, type) {
    event.preventDefault();
    if (type === "SECRET_KEY") {
      setConfirm({
        isOpen: true,
        title: translate("resources.bots.dialog.lanel_re_generate_secret"),
        content: translate("resources.bots.dialog.confirm_re_generate_secret"),
        type: "SECRET_KEY",
      });
    }
  }

  const generateSecretKey = () => {
    setGeneratingSecretKey(true);
    const newSecretKey = randomString(30);
    setFormSecret(newSecretKey);
    form.change("configs.form_secret", newSecretKey);
    // Set to bot
    setGeneratingSecretKey(false);
  };

  const handleConfirm = () => {
    if (confirm && confirm.type) {
      if (confirm.type === "SECRET_KEY") {
        generateSecretKey();
        handleDialogClose();
      }
    }
  };

  const handleDialogClose = () =>
    setConfirm({
      isOpen: false,
      title: "",
      content: "",
      type: "",
  });

  const onCopy = (event, type) => {
    event.preventDefault();
    if (type === "SECRET_KEY") {
      setCopyText(
        formSecret
          ? translate("resources.users.action.copied")
          : translate("resources.users.action.empty")
      );
      setIsCopiedToken(true);
      setTimeout(() => {
        setIsCopiedToken(false);
      }, 1000);
    }
  };

  const onWhitelistIpsChange = (listIps) => {
    const whitelistIps = listIps?.length ? listIps.join(',') : '';
    form.change("configs.whitelist_ips", whitelistIps);
  }

  const getCallbackUrl = (authUrl, botToken) => {
    return `${authUrl}/callback/bot/${botToken}/form`;
  }

  let testButtonColor = 'primary';
  let testIcon = <Autorenew />;
  let testLabel = 'Test';
  if (testButtonIcon === 'success') {
    testIcon = <SuccessIcon />;
    testButtonColor = 'success';
    testLabel = 'Success';
  }
  if (testButtonIcon === 'failed') {
    testIcon = <FailedIcon />;
    testButtonColor = 'error';
    testLabel = 'Failed';
  }

  return (
    <FormTab {...props} label="resources.bots.tabs.usage" icon={<SpeedIcon />}>
      {!props.bot?.id ? (
        <div
          style={{
            margin: "75px auto 100px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={50} thickness={2} />
        </div>
      ) : (
        <Grid
          container
          width="100%"
          spacing={2}
          display="flex"
          justifyContent="space-between"
        >
          <Grid item xs={8}>
            {/* General */}
            <h5>{translate("resources.bots.fields.usage_label_general")}</h5>
            <ContentWrapper>
              {/* Security */}
              <SubTitle>{translate("resources.bots.fields.usage_label_security_settings")}</SubTitle>
              <Box sx={{ color: "gray", fontSize: "0.8em" }}>
                {translate("resources.bots.fields.usage_label_security_settings_des", { brand: env.REACT_APP_BRAND })}
              </Box>
              <Field name="configs.whitelist_ips">{()=>null}</Field>
              <BotWhitelistIPs {...props} onWhitelistIpsChange={onWhitelistIpsChange} />
              {/* Encryption */}
              <SubTitle>{translate("resources.bots.fields.usage_label_encryption")}</SubTitle>
              <Box sx={{ color: "gray", fontSize: "0.8em", marginBottom: '10px' }}>
                {translate("resources.bots.fields.helper_encryption")}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", margin: "0 0 10px 15px" }}>
                <BooleanInput
                  source="configs.encryption_direct_messages"
                  label="resources.bots.fields.allow_encryption_direct_messages"
                  helperText="resources.bots.fields.helper_encryption_direct_messages"
                />
                <BooleanInput
                  source="configs.encryption_room_messages"
                  label="resources.bots.fields.allow_encryption_room_messages"
                  helperText="resources.bots.fields.helper_encryption_room_messages"
                />
              </Box>
              {/* Direct message */}
              <SubTitle>{translate("resources.bots.fields.usage_label_direct_message")}</SubTitle>
              <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
                <TextInput
                  source="configs.limit_direct_messages"
                  variant="outlined"
                  type="number"
                  label="resources.bots.fields.limit_direct_messages"
                  validate={[
                    required(),
                    number(),
                    regex(/^[1-9]\d*$/, "resources.common.validations.regex_positive_integer"),
                  ]}
                />
                <BooleanInput
                  source="configs.no_error_over_limit"
                  label="resources.bots.fields.no_error_over_limit"
                />
              </Box>
            </ContentWrapper>
            {/* Form */}
            <h5>{translate("resources.bots.fields.usage_label_form")}</h5>
            <ContentWrapper>
              <Box sx={{ whiteSpace: "nowrap", fontWeight: 'bold',marginBottom: '10px', fontSize: '0.8rem' }}>
                {translate("resources.bots.fields.usage_label_webhook_url")}
              </Box>
              <Box sx={{ color: "gray", fontSize: "0.8em", marginBottom: "10px" }}>
                {translate("resources.bots.fields.usage_label_webhook_url_des")}
              </Box>
              <Grid display="flex" container width="100%" spacing={1}
                    style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                <Grid item width="80%" sx={8}>
                  <TextInput
                    fullWidth
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                    source="configs.form_callback"
                    variant="outlined"
                    label="resources.bots.fields.usage_label_webhook_url"
                    onChange={(event) => {
                      setTestButtonIcon('test');
                      setCallbackUrl(event.target.value);
                    }}
                  />
                </Grid>
                <Grid sx={2}>
                  <Button
                    style={{ width: '135px', marginTop: '-12px' }}
                    color={testButtonColor}
                    variant="contained"
                    startIcon={testIcon}
                    onClick={() => testCallbackUrl(callbackUrl)}
                  >{testLabel}</Button>
                </Grid>
              </Grid>
              <div className={classes.copyClipboardBox} style={{ marginBottom: '25px' }}>
                  <span className="MuiFormHelperText-root MuiFormHelperText-filled MuiFormHelperText-marginDense">{
                    translate('resources.bots.fields.usage_label_webhook_url_helper', {
                      callback_url: truncate(getCallbackUrl(env.REACT_APP_AUTH_SERVER, props.botToken), 55, '...'),
                    })
                  }</span>
                <div className={classes.actionBox} style={{height: "unset"}}>
                  <CopyToClipboard text={getCallbackUrl(env.REACT_APP_AUTH_SERVER, props.botToken)}>
                    <span
                      className={classes.copyClipboard}
                      onClick={event => {
                        event.preventDefault();
                        setCopyCallbackURL(translate("resources.users.action.copied"));
                        setTimeout(() => {
                          setCopyCallbackURL(null);
                        }, 1000);
                      }}
                      onKeyDown={() => {}}
                    >
                      <Tooltip title={copyCallbackURL ?? 'Copied' } open={copyCallbackURL !== null} placement="top">
                        <FileCopyIcon style={{ fontSize: "23" }} />
                      </Tooltip>
                    </span>
                  </CopyToClipboard>
                </div>
              </div>

              <Box sx={{ whiteSpace: "nowrap", fontWeight: 'bold',marginBottom: '10px', fontSize: '0.8rem' }}>
                {translate("resources.bots.fields.usage_label_credentials")}
              </Box>
              <Box sx={{ color: "gray", fontSize: "0.8em", marginBottom: "10px" }}>
                {translate("resources.bots.fields.usage_label_credentials_des")}
              </Box>
              <div className={classes.copyClipboardBox}>
                <Field name="configs.form_secret">{()=>null}</Field>
                <FormControl fullWidth variant="standard">
                  <Input
                    readOnly={true}
                    value={formSecret ? truncate(formSecret, 30) : translate("resources.bots.validations.no_form_secret")}
                    disableUnderline={true}
                    className={[generatingSecretKey && classes.blurText]}
                  />
                </FormControl>
                <div className={classes.actionBox} style={{height: "unset"}}>
                  {!generatingSecretKey && (
                    <CopyToClipboard text={formSecret}>
                      <span
                        className={classes.copyClipboard}
                        onClick={event => onCopy(event, "SECRET_KEY")}
                        onKeyDown={() => {}}
                      >
                        <Tooltip
                          title={copyText}
                          open={isCopiedToken}
                          placement="top"
                        >
                          <FileCopyIcon style={{ fontSize: "23" }} />
                        </Tooltip>
                      </span>
                    </CopyToClipboard>
                  )}
                  <span
                    className={classes.randomPassword}
                    onClick={event => onGenerate(event, "SECRET_KEY")}
                    onKeyDown={() => {}}
                  >
                    <Tooltip
                      title={translate("resources.users.action.generate")}
                      placement="top"
                    >
                      <Autorenew
                        className={[generatingSecretKey && classes.spin]}
                        style={{
                          fontSize: "25",
                          animationDuration: `1s`,
                          animationDelay: `0.5s`,
                          animationIterationCount: "infinite",
                        }}
                      />
                    </Tooltip>
                  </span>
                </div>
              </div>
            </ContentWrapper>
            {/* Commands */}
            <h5>{translate("resources.bots.fields.usage_label_command")}</h5>
            <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
              <BooleanInput
                source="configs.command_jenkins"
                label="resources.bots.fields.enable_jenkins_command"
              />
              { /*
              <BooleanInput
                source="configs.command_jira"
                label="resources.bots.fields.enable_jira_command"
              />
              <BooleanInput
                source="configs.command_github"
                label="resources.bots.fields.enable_github_command"
              />
              <BooleanInput
                source="configs.command_system"
                label="resources.bots.fields.enable_system_command"
              />
              <BooleanInput
                source="configs.command_custom"
                label="resources.bots.fields.enable_custom_command"
              />
              */ }
            </Box>
          </Grid>
          <Confirm
            isOpen={confirm.isOpen}
            title={confirm.title}
            content={confirm.content}
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
          />
        </Grid>
      )}
    </FormTab>
  );
};

export default BotUsage;
